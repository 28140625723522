<template>
  <transition name="fade" mode="out-in">
    <nav v-if="!$route.meta.hideNav && !$store.getters.getHideInt && status">
      <router-link to="/" custom v-slot="{ navigate, isActive }">

        <button class="app-button rect-lg" @click="navigate" :active="isActive" role="link"><span>{{
            $t('nav.home')
          }}</span></button>
      </router-link>
      <router-link v-if="isTelegram" to="/kitties" custom v-slot="{ navigate }">
        <button class="app-button rect-sm" @click="navigate" role="link"
                style="display: flex;justify-content: center;align-items: center;"><img height="30" width="30"
                                                                                        :src="require('@/assets/svg/paw.svg')">
        </button>
      </router-link>
      <button class="app-button rect-sm" @click="changeLang()"
              style="display: flex;justify-content: center;align-items: center;">

        <img :src="require('@/assets/svg/lang.svg')">

      </button>
      <a class="app-button rect-sm" href="https://t.me/asubros" target="_blank"
         style="display: flex;justify-content: center;align-items: center;">
        <img :src="require('@/assets/svg/link.svg')">
      </a>
    </nav>
  </transition>
  <router-view v-slot="{ Component }" v-if="status">

    <transition name="fade" mode="out-in">
      <keep-alive include="HomeView">
        <component :is="Component"/>
      </keep-alive>
    </transition>
  </router-view>
  <div v-else>{{ $t('home.offline') }}</div>
</template>

<script>

import TextFade from '@/components/TextFade.vue'

window.Buffer = window.Buffer || require("buffer").Buffer;


export default {
  name: 'App',
  components: {TextFade},
  data() {
    return {
      status: 1
    }
  },
  methods: {
    changeLang() {
      //changeLangTonConnect
      let newLang;
      this.$i18n.locale == 'en' ? newLang = 'ru' : newLang = 'en'
      this.$i18n.locale = newLang
      this.$store.dispatch('changeLangTonConnect', newLang)
    },
    async checkStatus() {
      const url = 'https://api.asu.bot/q/status';
      const response = await fetch(url);
      const result = await response.json();
      this.status = result.ok
    }
  },
  beforeMount() {
    if (localStorage.getItem("old") == null) this.$router.push('/welcome');
  },
  mounted() {
    let lang = localStorage.getItem("lang")
    if (lang == null) lang = 'en'
    this.$store.dispatch('changeLangTonConnect', lang)
    this.$i18n.locale = lang

    this.checkStatus()
    if(this.isTelegram) window.Telegram.WebApp.expand()
  },
  computed: {
    isTelegram() {
      return window.Telegram.WebApp.initData !== ''
    }
  }
}
</script>
<style>

.mt-40 {
  margin-top: 40px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

body {
  /* filter:grayscale(); */
}

#app {
  transition: background 0.5s;
}

.main, nav {
  transition: opacity 0.5s;
}

.grayscale {
  filter: grayscale();
}

.hide-all {
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

#hide_app {
  transition: opacity 0.5s;
}

@keyframes pulse {
  from {
    transform: scale(.85);
  }
  50% {
    transform: scale(1);
  }
  to {
    transform: scale(.85);
  }
}

.hide-all div {
  width: 64px;
  cursor: pointer;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

nav {
  position: absolute;
  top: 44px;
  left: 0px;
  gap: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.flex {
  display: flex;
}

.gap-5 {
  gap: 5px;
}

.gap-4 {
  gap: 4px;
}

a {
  color: #fff;
  text-decoration: none;
}


body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden !important;
}

body {
  user-select: none;
  background: #000;
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', Arial, Tahoma, Verdana, sans-serif;
  font-weight: 590;
  display: flex;
  justify-content: center;
  align-items: center;
}


[data-tc-wallets-modal-connection-mobile="true"] [data-tc-transaction-sent-modal="true"], [data-tc-transaction-canceled-modal="true"], [data-tc-notification-confirm="true"], [data-tc-notification-tx-sent="true"], [data-tc-notification-tx-cancelled="true"], [data-tc-dropdown-button="true"] svg {
  display: none !important;
}

[data-tc-wallets-modal-connection-mobile="true"] [appearance="primary"][data-tc-button="true"] {
  color: #fff;
  background: #000;
}

tc-root [data-tc-dropdown-button="true"] {
  width: 100%;
}

[appearance="primary"][data-tc-button="true"] svg path {
  fill: #fff;
}

div[data-tc-text="true"], [data-tc-icon-button="true"] {
  color: #000 !important;
}

[data-tc-button="true"]:hover {
  transform: scale(1) !important;
}

[data-tc-wallets-modal-container="true"] {
  background-color: rgb(0 0 0) !important;
}

[data-tc-connect-button="true"] svg path {
  fill: #000 !important
}

tc-root button[data-tc-connect-button="true"] {
  width: 100%;
  background: #fff;
  color: #000;
}

/* button[data-tc-button="true"]:hover {
    transform: none !important;
} */

h1 a.small-button {
  padding: 0px 5px;
}

#connect tc-root div {
  width: 100% !important
}


p {
  line-height: 25px;
}

.app-button, .app-input {
  background: white;
  font-size: 15px;
  color: #000;
  cursor: pointer;
  font-weight: 590;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', Arial, Tahoma, Verdana, sans-serif;
  border: none;
  outline: none;
}

.mt-5 {
  margin-top: 5px
}

#info {
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-bottom: 4px;
}
button#refresh {
  display: flex;
  justify-content: center;
  align-items: center;
}
#info div.sub-block {
  display: flex;
  gap: 4px;
  flex-direction: column-reverse;
}

#placeholders {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
}

#placeholders div {
  gap: 4px;
}

input#amount {
  font-weight: bolder;
  text-align: right;
  padding: 9px;
  box-sizing: border-box;
  border: none !important;
  border-radius: 0px !important;
}

#refresh {
  cursor: pointer;
}

nav .app-button {
  position: relative;
}

#spinX2m, #spinX3m {
  display: none;
}

#app {
  max-width: 480px;
}

div[data-tc-wallets-modal-desktop="true"] div[data-tc-universal-qr-desktop="true"] div:nth-child(2) button {
  display: none;
}

button.app-button[active="true"]::before {
  content: "━";
  position: absolute;
  font-size: 25px;
  font-weight: bold;
}


.rect-sm-b, .rect-sm, .rect-md, .rect-lg, .rect-xl {
  height: 40px;
}

.rect-sm-b {
  width: 52.5px;
}

.rect-sm {
  width: 50px;
}

.rect-md {
  width: 168px;
}

.rect-lg {
  width: 200px;
}

.rect-xxl {
  width: 276px;
}

.rect-sml {
  width: 109px;
}

#claim {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 5px;
  position: relative;
}

.rect-xl {
  width: 222px;
}

#claim .small-button.left {
  position: absolute;
  top: 5px;
  right: 5px;
  transform: skew(10deg, -5deg);
  font-size: 10px;
  border: 2px dashed;
}

#claim .small-button.right {
  position: absolute;
  top: 3px;
  left: -10px;
  transform: skew(37deg, 321deg);
  font-size: 10px;
  border: 2px dashed #fff;
  background: #000;
  color: #fff;
  padding: 0px 5px;
}


span.small-button.right:before {
  content: "📌";
  position: absolute;
  top: -15px;
  font-size: 15px;
  left: 20px;
}

h1 {
  user-select: none;
}

.mobile-desc {
  display: none !important;
}

@media screen and (max-width: 296px) {
  div#app {
    transform: scale(0.9);
    height: 100%;
  }
}

@media screen and (min-width: 576px) {
  .hidden-ld {
    display: none;
  }

  .screen-column {
    flex-direction: row;
  }
}

@media screen and (max-width: 576px) {
  .screen-column {
    flex-direction: column;
  }
  .w-100-m {
    transition: 0s;
    width: 100%;
  }
  h1 {
    line-height: 30px;
    font-size: 20px;
  }

  .alert {
    padding: 8px 10px !important;
  }

  .mobile-desc {
    display: flex !important;
    margin: 15px 5px 5px 5px;
  }

  .rect-lg {
    width: 168px;
  }

  nav .rect-lg {
    width: 125px;
  }

  #info, #info div.sub-block {
    flex-direction: column;
  }

  #app {
    max-width: 276px;
  }

  #spinX2, #spinX3 {
    display: none;
  }

  #spinX2m, #spinX3m {
    display: inline-block;
  }

  .sub-block-affirm {
    flex-direction: row !important;
    justify-content: space-between;
  }

  div#placeholders {
    justify-content: space-between;
  }

  /* .sub-block-affirm .app-button {
      min-width: 168px;
  } */
  .next {
    position: absolute;
  }

  nav, .next {
    bottom: 10%;
    top: unset;
  }

  .hide-all {
    bottom: 0;
    top: unset;
  }

  #claim .small-button.left, #claim .small-button.right {
    display: none;
  }

  .hidden-sd {
    display: none !important;
  }
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.75rem;
  }
  75% {
    margin-left: -0.75rem;
  }
  100% {
    margin-left: 0rem;
  }
}

button img {
  pointer-events: none;
}

img {
  pointer-events: none;
}

button:has(> img) {
  border: none;
}

.mb-4 {
  margin-bottom: 4px;
}

.alert {
  padding: 0px 10px;
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  min-height: 40px;
}

.alert .close {
  position: absolute;
  right: 6px;
  top: 6px;
  background: #fff;
  padding: 0px 2px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
}

nav span {
  min-width: 70px;
  display: inline-block;
  text-align: left;
}

button[data-tc-connect-button="true"] div {
  display: contents;
}

.app-button {
  position: relative
}

#app {
  background: #000000bd;
  padding: 25px;
}

h1, h2 {
  margin-top: 0;
}

.small-button {
  text-wrap: nowrap;
}

div[data-tc-wallets-modal-container="true"] > div > div:last-child {
  display: none;
}

.old-link {
  text-decoration: underline;
  color: #0000EE !important;
  padding: 1px;
  cursor: pointer;
  text-wrap: nowrap;
}

@media screen and (max-height: 624px) {
  nav, .hide-all {
    display: none;
  }
}

.scroll {
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin: 0;
  padding: 0;
}

.m-scroll {
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
}


@keyframes scroll {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}
</style>
