<template>
  <transition-group name="digit-fade" tag="div">
    <div v-for="digit in digits" :key="digit.id" class="digit"
         :style="{ ...digit.style, fontSize: `${digit.fontSize}px` }">
      <span class="digit-symbol" :style="{ fontSize: `${digit.fontSize * 0.7}px` }">1 TON is... </span> <span
        class="digit-value">${{ parseFloat(digit.value).toFixed(2) }}</span>
      <span style="margin-left: 4px;position:relative" @click="catchKittie(digit.emoji_name, digit.emoji_type)">
        <transition name="fade" mode="out-in">
              <KittieLottieEffect :type="'success'"
                                  v-if="claimed_cats.includes(digit.emoji_name + '.' + digit.emoji_type)"
                                  id="spin-effect1"/>
        </transition>
        <transition name="fade" mode="out-in">
              <KittieLottieEffect :type="'error'" :speed="0.3"
                                  v-if="waiting_cats.includes(digit.emoji_name + '.' + digit.emoji_type)"
                                  id="spin-effect1"/>
        </transition>


        <span>
              <img :src="'/webp/'+digit.emoji_name + '.' + digit.emoji_type"
                   :height="digit.fontSize * 1.5" alt="emoji">
        </span>
      </span>
    </div>
  </transition-group>
</template>

<script>
import KittieLottieEffect from "@/components/KittieLottieEffect.vue";

export default {
  components: {KittieLottieEffect},
  data() {
    return {
      claimed_cats: [],
      waiting_cats: [],
      buffer: [],
      digits: [],
      delta: 50,
      edgePadding: 50,
    };
  },
  computed: {
    store() {
      return this.$store;
    },
  },
  methods: {
    async catchKittie(name, type) {
      if (this.buffer.includes(name + '.' + type)) return null;
      this.buffer.push(name + '.' + type)
      if (window.Telegram.WebApp.initData === '') return null;
      const url = 'https://api.asu.bot/q/kittie';
      const data = {
        num: name,
        type: type,
        hs: window.Telegram.WebApp.initData
      };
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      };

      if (!this.claimed_cats.includes(name + '.' + type)) await fetch(url, options)
          .then(response => {
            return response.json();
          })
          .then(data => {
            if (data.ok === false)
              this.waiting_cats.push(name + '.' + type)
            else {
              this.claimed_cats.push(name + '.' + type)
              this.$store.dispatch('setForceRefreshKitties', true)
            }

          })
          .catch(error => {
            console.error('Error:', error);
          });
    },
    async getNextKittie() {
      if (window.Telegram.WebApp.initData === '') return null;

      const url = 'https://api.asu.bot/q/next_kittie';
      const data = {
        hs: window.Telegram.WebApp.initData
      };
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();

        if (result.ok === false) {
          return null;
        } else {
          let obj = {};
          if (typeof (result.num) != 'undefined') obj.num = result.num;
          obj.data = result.data;
          return obj;
        }
      } catch (error) {
        console.error('Error:', error);
        return null;
      }
    },
    async generateDigit() {

      const id = Math.random().toString(36).substr(2, 9);
      // const value = (Math.random() * (250 - 10) + 10).toFixed(2); // Generate value in range from 10 to 250
      let startX, startY;

      if (window.innerWidth < 576) { // mobile
        startX = Math.random() * window.innerWidth * 0.1;
        if (Math.random() < 0.7) {
          startY = Math.random() * (window.innerHeight * 0.2);
        } else {
          startY = Math.random() * (window.innerHeight * 0.1) + window.innerHeight * 0.7;
        }
        if (this.store.state.hideInt) {
          startY = Math.random() * (window.innerHeight * 0.9);
        }
      } else { // desktop
        startY = Math.random() * (window.innerHeight * 0.6) + window.innerHeight * 0.15;
        if (Math.random() < 0.5) {
          startX = Math.random() * (window.innerWidth * 0.2) + window.innerWidth * 0.1;
        } else {
          startX = Math.random() * (window.innerWidth * 0.25) + window.innerWidth * 0.55;
        }
      }

      // Generate final coordinates considering delta
      const angle = Math.random() * Math.PI * 2; // Random angle in radians
      const distance = Math.random() * this.delta + 50; // Random distance from the starting point
      let endX = startX + Math.cos(angle) * distance;
      let endY = startY + Math.sin(angle) * distance;

      // Determine the direction of movement to bounce off the edges of the screen
      const directionX = endX > startX ? 1 : -1;
      const directionY = endY > startY ? 1 : -1;

      // Recalculate final coordinates to avoid going off the screen edge
      if (endX < this.edgePadding || endX > window.innerWidth - this.edgePadding) {
        endX = startX - directionX * this.edgePadding;
      }
      if (endY < this.edgePadding || endY > window.innerHeight - this.edgePadding) {
        endY = startY - directionY * this.edgePadding;
      }

      // Determine font size based on the number value
      let next_kittie = await this.getNextKittie();
      let value = null;
      if (next_kittie != null) {
        value = typeof next_kittie.num !== 'undefined' && next_kittie.num !== null && !isNaN(next_kittie.num) ? next_kittie.num : null;
        next_kittie = next_kittie.data;
      } else value = (Math.random() * (250 - 10) + 10).toFixed(2);
      const fontSize = 16 + Math.sqrt(parseFloat(value)); // Increase font size with increasing number value

      const duration = 4000;
      const fadeDuration = 1000;
      const style = {
        top: `${startY}px`,
        left: `${startX}px`,
        opacity: 0,
        transition: `opacity ${fadeDuration}ms ease-in-out, top ${duration}ms linear, left ${duration}ms linear`,
        fontSize: fontSize,
      };
      if (next_kittie === null) {
        const pics = {
          webp: 66,
        };
        let emoji_type = 'webp';
        let emoji_name = Math.floor(Math.random() * pics[emoji_type]) + 1;
        this.digits.push({id, value, fontSize, style, endX, endY, emoji_type, emoji_name});
      } else {
        let parts = await next_kittie.split('.');
        let emoji_type = parts[1];
        let emoji_name = parts[0];
        this.digits.push({id, value, fontSize, style, endX, endY, emoji_type, emoji_name});
      }
      setTimeout(() => {
        const index = this.digits.findIndex((d) => d.id === id);
        if (index !== -1 && this.digits[index]) {
          this.digits[index].style.top = `${endY}px`;
          this.digits[index].style.left = `${endX}px`;
          this.digits[index].style.opacity = 0.75;
        }
      }, 0);

      setTimeout(() => {
        const index = this.digits.findIndex((d) => d.id === id);
        if (index !== -1 && this.digits[index]) {
          this.digits[index].style.opacity = 0;
          setTimeout(() => {
            this.digits.splice(index, 1);
          }, fadeDuration);
        }
      }, duration - fadeDuration);
      setTimeout(() => {
        this.claimed_cats = []
        this.waiting_cats = []
        this.buffer = []
        this.generateDigit()
      }, duration)
    },
  },
  mounted() {
    for (let i = 1; i <= 66; i++) {
      const img = new Image();
      img.src = `/webp/${i}.webp`;  // Убедитесь, что путь правильный
      img.onload = () => {
        console.log(`Изображение ${i}.webp предзагружено`);
      };
    }
    this.generateDigit()
  },
};
</script>

<style scoped>
.digit {
  position: absolute;
  white-space: nowrap; /* Запрет на перенос строк */
  z-index: -99;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 4px
}

.digit-symbol {
  font-size: 16px; /* Размер символа $ */
}

.digit-fade-enter-active, .digit-fade-leave-active {
  transition: opacity 1s ease-in-out;
}

.digit-fade-enter, .digit-fade-leave-to {
  opacity: 0;
}

.digit {
  display: flex;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
