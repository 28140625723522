<template>
  <Vue3Lottie :speed="speed" :animationData="require('@/assets/lottie/' + type + '.json')"
              style="position: absolute;    top: 50%;    transform: translate(-50%, -50%);    left: 50%; z-index: 1;"
              :height="80" :width="80"/>
</template>

<script>
export default {
  name: 'ButtonLottieEffect',
  props: {
    type: {
      type: String,
      default: 'success'
    },
    speed: {
      type: Number,
      default: 1
    },
  },
  computed: {
    rand() {
      return Math.floor(Math.random() * 5) + 1;
    },
  }
}
</script>