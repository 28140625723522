import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AdminView from '../views/AdminView.vue'
import WelcomeTo from '../views/WelcomeTo.vue'
import TweetWindow from '../views/TweetWindow.vue'
import KittiesView from "@/views/KittiesView";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'Make ASU' }
  },  
  {
    path: '/kitchen',
    name: 'admin',
    component: AdminView,
    meta: { title: 'Kitchen' }
  },
  {
    path: '/about',
    name: 'about',
    meta: { title: 'About ASU' },
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/welcome',
    name: 'welcomeTo',
    component: WelcomeTo,
    meta: { title: 'Welcome to ASU', hideNav: true }
  },
  {
    path: '/kitties',
    name: 'kitties',
    component: KittiesView,
    meta: { title: 'Kitties' }
  },
  {
    path: '/u-r-awesome',
    name: 'tweetWindow',
    component: TweetWindow,
    meta: { title: 'Awesome!', hideNav: true }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to) => {

  document.title = to.meta?.title ?? 'ASU'

})
export default router
