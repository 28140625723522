<template>
  <Vue3Lottie :animationData="require('@/assets/lottie/' + rand + '.json')"
              style="position: absolute;    top: 50%;    transform: translate(-50%, -50%);    left: 50%; z-index: 1;"
              :height="150" :width="150"/>
</template>

<script>
export default {
  name: 'ButtonLottieEffect',
  computed: {
    rand() {
      return Math.floor(Math.random() * 5) + 1;
    },
  }
}
</script>